.icon a {
  display: block;
  margin: 0;
  padding: 0;
}

.icon > a {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  padding: 0;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin: 2rem;
}

.icon:hover {
  cursor: pointer;
  animation-name: spin;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

.icon img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-25deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
