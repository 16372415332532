.card {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  margin-top: 1.5rem;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.project-name {
  font-size: 2rem;
  margin-bottom: 10px;
}

.project-description {
  margin-bottom: 20px;
}

.expand-button {
  width: 1rem;
  height: 1rem;
  margin-top: 2rem;
  border-right: 0.5rem solid #3f3f3f;
  border-bottom: 0.5rem solid #3f3f3f;
  transform: rotate(45deg);
}

.expand-button:hover {
  cursor: pointer;
}

.close-button {
  width: 1rem;
  height: 1rem;
  margin-top: 3rem;
  border-left: 0.5rem solid #3f3f3f;
  border-top: 0.5rem solid #3f3f3f;
  transform: rotate(45deg);
}

.close-button:hover {
  cursor: pointer;
}

.additional-details {
  margin-top: 20px;
}

.card.expanded {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.card.expanded > div:not(.additional-details) {
  display: none;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.projectImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  transition: transform 0.3s ease;
}

.projectImage:hover {
  transform: scale(1.1);
}
