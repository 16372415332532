.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar {
  display: flex;
  justify-content: center;
}

.nav-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.nav-list li {
  margin-right: 20px;
}

.nav-list li:last-child {
  margin-right: 0;
}

.nav-list a {
  color: #fff;
  text-decoration: none;
}
