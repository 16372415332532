.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-image {
  width: 30% !important;
  object-fit: cover;
  cursor: pointer;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.1);
}

.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  cursor: pointer;
}

.expanded-image {
  height: 95% !important;
  width: 95% !important;
  object-fit: contain;
}
