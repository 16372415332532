body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .experienceContainer {
  display: flex;
  flex-direction: column;
  background-color: blue;
}
.cardContainer {
  display: flex;
  justify-content: space-evenly;
  border: red 1px solid;
}
.square {
  border: red solid 1px;
  /* aspect-ratio: 1; */
/* background-color: #3f3f3f;
} */
