.footer {
  background-color: #333;
  padding: 20px;
  text-align: center;
}

.social-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.icon {
  font-size: 24px;
  color: #333;
  margin: 0 10px;
}
