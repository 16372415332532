.singleExperienceContainer {
  display: flex;

  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.timeline-card {
  position: relative;
  width: 40%;
  padding: 20px;

  background-color: #fff;
  flex-direction: column;
  border: 1px solid #979494;

  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  border-radius: 6px;
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5); */
}

.timeline-card.left {
  align-self: flex-start;
  /* order: -1; */
}

.timeline-card.right {
  align-self: flex-end;
  /* order: 1; */
}

/* Additional styling for card contents */
.timeline-card h3 {
  margin-top: 0;
}

.timeline-card h4 {
  margin: 10px 0;
}

.timeline-card p {
  margin: 0;
}

.trianglePointerRight {
  position: absolute;
  z-index: 2;
  height: 2rem;
  width: 2rem;
  background-color: #fff;
  transform: rotate(45deg);
  top: 1rem;
  right: -1.1rem;
  border-right: 1px solid #979494;
  border-top: 1px solid #979494;
}
.trianglePointerLeft {
  position: absolute;
  z-index: 2;
  height: 2rem;
  width: 2rem;
  background-color: #fff;
  transform: rotate(45deg);
  top: 1rem;
  left: -1.1rem;
  border-left: 1px solid #979494d3;
  border-bottom: 1px solid #979494;
}

.trianglePointerLeft::before {
  left: 0;
  transform: scaleX(0.25); /* Adjust the scaleX value for the desired side */
  transform-origin: left;
}

.trianglePointerLeft::after {
  right: 0;
  transform: scaleX(0.25); /* Adjust the scaleX value for the desired side */
  transform-origin: right;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timeLineImage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 5rem;
  height: 5rem;
}

.hidden {
  display: none;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 1100px) {
  .timeline {
    width: 95%;
  }
}

@media screen and (max-width: 825px) {
  .timeline {
    width: 80%;
  }

  .timeline-card {
    width: 85%;

    z-index: 3;
    margin-top: 2rem;
  }

  .timeline-card.left {
    align-self: center;
  }

  .timeline-card.right {
    align-self: center;
  }
  .trianglePointerRight {
    display: none;
  }

  .trianglePointerLeft {
    display: none;
  }
}
