.education-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;
  margin: 0 auto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);

  /* max-width: 400px; */
}

.education-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.university-logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}

.university-info {
  flex-grow: 1;
}

.university-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.date-range {
  font-size: 16px;
  color: #888;
}

.education-details {
  margin-top: 15px;
}

.info-item {
  margin-bottom: 5px;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}
